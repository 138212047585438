.el-textarea /deep/ .el-textarea__inner {
  min-height: 100px !important;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/ .el-upload--picture-card {
  display: none;
}
.bannerBox .el-form-item__content .hide /deep/ .el-upload--picture-card i {
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-upload-list--picture-card /deep/ .el-upload-list__item-actions {
  z-index: 9999;
}
.el-tooltip__popper {
  max-width: 45% !important;
}
